.homePageContainer {
  width: 100%;
  margin: 60px auto 20px auto;
  max-width: 1100px;
  display: block;
}

.whoWeAre {
  width: 100%;
  border-right: none;
  height: 370px;
  &Inner {
    display: grid;
    grid-template-columns: 300px 350px 300px;
    grid-template-rows: repeat(auto-fit, 275px);
    grid-auto-flow: dense;
    column-gap: 67px;
    row-gap: 20px;
    list-style: none;
  }
}


.ourGoal,
.news {
  width: 100%;
  margin-bottom: 80px;
}

.ourGoalText,
.newsText {
  padding: 20px;
  border: 2px solid #5bc4de;
  border-radius: 10px;
  text-align: center;
  color: #4d4959;
}

.ourGoalText {
  font-size: 16px;
}

.newsTextItem {
  display: flex;
  justify-content: center;
  line-height: 2.5em;
  span {
    color: #0086b3;
    cursor: pointer;
  }
  span:hover {
    color: #0088cc;
  }
}

.whatWeUse {
  width: 100%;
  min-height: 610px;
}

.whatWeUseRow {
  display: flex;
  height: 290px;
}

.mortgage,
.robot2,
.database {
  position: initial;
}

.homeText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  color: #4d4959;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  transition: z-index 0.5s, box-shadow 1s;
  background-color: #fcffff;
  b {
    font-size: 16px;
  }
  .number {
    font-size: 18px;
    font-family: Raleway, sans-serif;
  }
  &Inner {
    margin-left: auto;
  }
}

.automation {
  height: 233px;
  width: 350px;
}

.ad {
  height: 102px;
}

.homePageImg {
  height: 60px;
  position: initial;
  transition: z-index 0.5s, height 0.5s;
  margin-left: auto;
}

.displayBlock {
  display: block;
}

.marginRight {
  margin-right: auto;
}

.h100 {
  height: 100px;
}

.flat:hover,
.mortgage:hover,
.robot2:hover,
.transaction:hover,
.database:hover {
  height: 60px;
}

.bdContainer {
  height: 235px;
  width: 275px;
}

.mySql {
  height: 130px;
  position: absolute;
  left: 61px;
  top: 40px;
}

.mySql:hover {
  height: 150px;
}

.mongo {
  position: absolute;
  left: 195px;
  top: 41px;
}

.proxySql {
  position: absolute;
  left: 91px;
  top: 167px;
}

.elastic {
  position: absolute;
  left: 0;
  top: 49px;
}

.elastic:hover,
.elasticSearch:hover,
.kibana:hover {
  height: 75px;
}

.redis {
  position: absolute;
  top: 121px;
  left: 191px;
}

.rabbit {
  position: absolute;
  left: 0;
  top: 131px;
  height: 50px;
}

.rabbit:hover {
  height: 63px;
}

.javaContainer {
  margin: 0 auto;
  width: 214px;
  height: 230px;
}

.java2 {
  position: absolute;
  height: 120px;
  top: 36px;
  left: 15px;
}

.java2:hover {
  height: 140px;
}

.spring {
  position: absolute;
  top: 82px;
  left: 109px;
}

.spring:hover {
  height: 70px;
}

.go {
  position: absolute;
  left: 24px;
  top: 162px;
}

.pythonLang {
  position: absolute;
  left: 109px;
  top: 166px;
}

.hadoopContainer {
  width: 245px;
  height: 200px;
}

.hadoop {
  position: absolute;
  height: 125px;
  left: 66px;
  top: 26px;
}

.hadoop:hover {
  height: 145px;
}

.python {
  position: absolute;
  top: 44px;
  left: 10px;
  height: 50px;
}

.python:hover {
  height: 65px;
}

.scipy {
  position: absolute;
  top: 138px;
  left: 93px;
  height: 70px;
}

.scipy:hover {
  height: 85px;
}

.pandas {
  position: absolute;
  top: 116px;
  left: 184px;
}

.anaconda {
  position: absolute;
  top: 123px;
  left: 10px;
  height: 50px;
}

.numpy {
  position: absolute;
  left: 193px;
  top: 42px;
}

.reactContainer {
  margin-right: auto;
  margin-left: auto;
  height: 175px;
  width: 198px;
}

.reactImg {
  position: absolute;
  height: 100px;
  top: 60px;
  left: 0;
}

.reactImg:hover {
  height: 120px;
}

.ts {
  position: absolute;
  left: 95px;
  top: 50px;
}

.mobxImg {
  position: absolute;
  height: 45px;
  top: 113px;
  left: 102px;
}

.mobxImg:hover {
  height: 60px;
}

.kubernetesContainer {
  height: 225px;
  width: 265px;
  margin-left: auto;
  margin-right: 0;
}

.kubernetes2 {
  position: absolute;
  top: 48px;
  left: 85px;
  height: 98px;
}
.kubernetes2:hover {
  height: 115px;
}

.vault {
  position: absolute;
  top: 39px;
  height: 70px;
  left: 0;
}

.vault:hover {
  height: 90px;
}

.kiali {
  position: absolute;
  top: 163px;
  left: 75px;
  height: 50px;
}

.istio {
  position: absolute;
  top: 109px;
  left: 207px;
}

.dockerImg {
  position: absolute;
  top: 159px;
  left: 140px;
  height: 65px;
}

.dockerImg:hover {
  height: 85px;
}

.flagger {
  position: absolute;
  top: 43px;
  left: 204px;
}

.helm {
  position: absolute;
  top: 122px;
  left: 8px;
  height: 55px;
}

.monitoringContainer {
  width: 216px;
  height: 200px;
  margin-right: 0;
  margin-left: auto;
}

.zabbix {
  position: absolute;
  top: 40px;
  left: 0;
}

.powerBi {
  position: absolute;
  top: 37px;
  left: 160px;
}

.grafanaImg {
  position: absolute;
  top: 113px;
  left: 147px;
}

.jenkins2 {
  position: absolute;
  top: 63px;
  left: 71px;
  height: 80px;
}

.jenkins2:hover {
  height: 95px
}

.jira2 {
  position: absolute;
  top: 160px;
  left: 82px;
}

.puppet2 {
  position: absolute;
  top: 115px;
  left: 14px;
}

.content {
  padding: 5px;
  text-align: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.containerTitle {
  position: absolute;
  top: 0;
  left: 72px;
  color: #4d4959
}

.bigDataTitle {
  left: 97px;
}

.frontendTitle {
  left: 46px;
}

.monitoringTitle {
  left: 62px;
}

.circle {
  font-size: 7px!important;
  margin-right: 10px;
}

.whatWeUseRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-template-rows: repeat(auto-fit, 275px);
  grid-auto-flow: dense;
  column-gap: 100px;
  row-gap: 20px;
  list-style: none;
}

@media screen and (max-width: 1130px) {
  .whoWeAre {
    height: 490px;
  }
  .whoWeAreInner {
    grid-template-columns: 300px 350px;
    grid-template-rows: repeat(auto-fit, 275px);
    margin-left: calc((100vw - 700px)/2);
    column-gap: 50px;
    row-gap: 0;
  }
  .whoWeAreLastBlock {
    display: flex;
  }
  .ad {
    width: 350px;
    height: 84px;
    margin-left: 50px;
  }
  .whatWeUse {
    height: 910px;
    &Row {
      grid-template-columns: repeat(auto-fit, 285px);
      margin-left: calc((100vw - 700px)/2);
    }
  }
  .javaContainer {
    height: 250px;
  }
  .kubernetesContainer {
    margin-right: auto;
  }
  .monitoringContainer {
    margin-right: auto;
  }
  .container {
    position: relative;
    justify-content: center;
  }
}

@media screen and (max-width: 765px) {
  .whatWeUse {
    height: 1620px;
    &Row {
      grid-template-columns: repeat(auto-fit, 360px);
      margin-left: calc((100vw - 360px)/2);
    }
  }
  .whoWeAreLastBlock {
    display: initial;
  }
  .ad {
    width: 300px;
    height: 102px;
    margin-left: initial;
  }
  .whoWeAre {
    height: 790px;
  }
  .whoWeAreInner {
    display: block;
    margin-left: auto;
  }
  .homeText {
    margin: 0 auto;
  }
  .automation {
    width: 300px;
  }
  .news {
    margin-bottom: 0;
  }
  .container {
    margin: 0 auto 20px auto;
  }
  .whatWeUse {
    .container {
      margin: 0 20px;
      width: 275px;
    }
    .kubernetesContainer,
    .hadoopContainer,
    .reactContainer,
    .monitoringContainer {
      margin-bottom: 30px;
    }
  }
  .containerTitle {
    position: initial;
    margin-top: 0;
    margin-bottom: auto;
  }
  .zabbix {
    left: 19px;
  }
  .powerBi {
    left: 192px;
  }
  .grafanaImg {
    left: 194px;
  }
  .jenkins2 {
    left: 104px;
  }
  .jira2 {
    left: 111px;
  }
  .puppet2 {
    left: 23px;
  }
  .reactImg {
    left: 52px;
  }
  .ts {
    left: 154px;
  }
  .mobxImg {
    left: 161px;
  }
  .hadoop {
    left: 78px;
  }
  .python {
    left: 21px;
  }
  .scipy {
    left: 105px;
  }
  .pandas {
    left: 199px;
  }
  .anaconda {
    left: 19px;
  }
  .numpy {
    left: 204px;
  }
  .java2 {
    left: 53px;
  }
  .spring {
    left: 149px;
  }
  .go {
    left: 68px;
  }
  .pythonLang {
    left: 158px;
  }
  .mySql {
    left: 73px;
  }
  .mongo {
    left: 207px;
  }
  .proxySql {
    left: 107px;
  }
  .redis {
    left: 208px;
  }
  .kubernetes2 {
    left: 91px;
  }
  .istio {
    left: 213px;
  }
  .dockerImg {
    left: 148px;
  }
  .flagger {
    left: 212px;
  }
}
