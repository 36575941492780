@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.header {
  z-index: 50;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f5fbfc;
  position: sticky;
  &Top {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 0;
    &List {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      .linkHeader {
        margin: 0 auto;
      }
      &Link {
        color: #4d4959;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: 'Roboto Slab', sans-serif;
      }
    }
  }

  &Bottom {
    background: #5bc4de;
    background: linear-gradient(to right, #f5fbfc, #5bc4de, #f5fbfc);
    width: 100%;
    padding: 3px 0;
  }
}

.headerLogo {
  margin-right: 15px;
  cursor: pointer;
}

.main {
  width: 100%;
  padding: 40px 20px 70px 20px;
  margin-bottom: 100px;
  min-height: calc(100vh - 191px);
}

.text {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 16px;
  width: 100%;
  max-width: 1100px;
  margin: 20px auto 0 auto;
  color: #4d4959;
  line-height: 2;
  display: flex;
  justify-content: center;
}

.marginBottom {
  margin-bottom: 20px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 0;
  color: #4d4959;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.officeLabel,
.articlesLabel,
.stackLabel,
.vacancyLabel {
  color: #4d4959;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.office,
.articles,
.stack,
.vacancy,
.vacancies {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.articlesGroup,
.stackGroup,
.vacanciesGroup {
  padding-bottom: 20px;
  line-height: 2;
}

.article,
.link,
.item,
.vacancy {
  padding-bottom: 10px;
  font-family: 'Roboto Slab', sans-serif;
  font-size: 16px;
  span {
    color: #005580;
    letter-spacing: 1.5px;
    cursor: pointer;
  }
}

.article span:hover,
.link span:hover,
.vacancy span:hover {
  color: #08c;
}

.imgBook {
  background: no-repeat center/80% url(./book.jpg);
  height: 500px;
}

.itemStack {
  font-size: 15px;
  color: #4d4959;
  text-decoration: none;
  font-family: 'Roboto Slab', sans-serif;
}

.burger {
  display: none;
  font-size: 1.5em;
  margin-right: 20px;
  cursor: pointer;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .linkHeader {
    display: none;
  }
  .burger {
    display: inline;
  }
  .headerLogo {
    margin-left: 20px;
  }
  .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 520px) {
  .text {
    font-size: 15px;
  }
}

.dropdownItem {
  span {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    width: 200px;
    color: #4d4959;
    font-size: 13px;
    font-weight: 700;
  }
}

.dropdownItem:hover {
  background-color: #9ed1de;
}

.dropdownMenu {
  background-color: #f5fbfc;
  width: 200px;
}

.dropdownMenu:first-child {
  margin-top: 10px;
}

.popup {
  padding: 0!important;
  background-color: #f5fbfc!important;
}

.popup::before {
  background-color: #f5fbfc!important;
}

.active {
  color: black;
  font-size: 16px;
}

.inlineBlock {
  display: inline-block;
}

.siteLink,
.mapLink {
  color: #005580;
  cursor: pointer;
}

.siteLink:hover,
.bookLink:hover,
.mapLink:hover,
.hh:hover {
  color: #08c;
}

footer {
  border-top: 4px double transparent;
  border-image: linear-gradient(to right, #f5fbfc, #5bc4de, #f5fbfc);
  border-image-slice: 1;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin-top: -100px;
  background: #f5fbfc;
  height: 100px;
}

.footerText {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 14px;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  color: #4d4959;
  letter-spacing: 1.5px;
  line-height: 2;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 765px) {
  .footerText {
    font-size: 12px;
  }
}

.articleText {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 14px;
  width: 100%;
  margin: auto;
  color: #4d4959;
  line-height: 2;
  padding: 20px 0;
}

.vacancyContainer,
.articleContainer {
  margin: 20px 0;
  border: 1px dotted rgba(77, 73, 89, 0.5);
  border-radius: 10px;
  background: #fafcfc;
  position: relative;
  padding: 30px 30px 55px 30px;
}

.vacancyHeader,
.articleHeader {
  color: #4d4959;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Roboto Slab', sans-serif;
}

.vacancyHeader {
  padding-bottom: 10px;
}

.vacancyContainer {

}

.right {
  position: absolute;
  right: 30px;
  font-family: 'Roboto Slab', sans-serif!important;
}

.vacancyOption {
  padding: 5px;
  color: #4d4959;
  font-family: 'Roboto Slab', sans-serif;
}

.taskItem {
  padding-bottom: 10px;
  color: #4d4959;
  text-decoration: none;
  font-family: 'Roboto Slab', sans-serif;
}

.hh {
  color: #005580;
  letter-spacing: 2px;
  font-family: 'Roboto Slab', sans-serif;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 20px;
}

.textAboutSrg {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 16px;
  width: 100%;
  max-width: 1100px;
  margin: 20px auto;
  color: #4d4959;
  line-height: 2;
}

.homePageText {
  align-items: center;
  text-align: center;
  margin-top: -10px;
}

.marginAuto {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.margin {
  margin: 0 15px;
}

.fontSize17 {
  font-size: 17px;
}

.gallery {
  width: 80%;
  margin: 15px auto;
  min-height: 700px;
}

.minHeight {
  min-height: 750px;
}

.officeHeaders {
  display: flex;
  justify-content: center;
  margin: 25px 0 15px 0;
}

.habrImg1 {
  height: 200px;
  margin: 0 auto 10px auto;
  border-radius: 10px;
}

.habrImg {
  height: 200px;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
}

.displayFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayBlock {
  display: block;
}

@media screen and (max-width: 765px) {
  .habrImg1 {
    height: 150px;
  }
  .displayFlex {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .habrImg1 {
    height: 100px;
  }
}
@media screen and (max-width: 460px) {
  .habrImg {
    height: 150px;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media screen and (max-width: 420px) {
  .habrImg1 {
    height: 80px;
  }
}

@media screen and (max-width: 335px) {
  .habrImg1 {
    height: 70px;
  }
}

@media screen and (max-width: 360px) {
  .habrImg {
    height: 120px;
    margin-left: 0;
    margin-top: 10px;
  }
}

.displayFlexVacancies {
  display: flex;
}

.displayFlexVacancy {
  display: flex;
  align-items: center;
}

.humanIcon {
  margin-right: 15px;
}

.humanIconVacancy {
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.lampIcon {
  margin-right: 20px;
}

.marginRight10 {
  margin-right: 10px;
}

.footerImgHeight {
  height: 20px;
}

.buttonUi {
  border: 1px solid rgba(91, 196, 222, 1)!important;
}

.homePageContainer {
  width: 100%;
  margin: 60px auto 20px auto;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 45px;
}

.homePageImg {
  height: 60px;
  position: absolute;
  left: 180px;
  transition: z-index 0.5s, height 0.5s;
}

.homePageImg:hover {
  z-index: 10;
}

.homePageImg:hover {
  height: 75px;
}

.margins {
  margin-top: 86px;
  margin-left: 67px;
}

.exp {
  width: 200px;
  height: 80px;
  border: 2px solid #ccc;
  float: right;
  position: sticky;
  top: 91px;
  z-index: 1;
  background-color: #f5fbfc;
  border-radius: 10px;
  padding: 10px;
}

.marginRight50 {
  margin-right: 50px;
}

.arrow {
  height: 25px;
  float: right;
  background-color: #f5fbfc;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-radius: 0 0 10px 10px;
  padding: 2px;
  margin-top: 9px;
  cursor: pointer;
}

.onlyArrow {
  position: sticky;
  top: 91px;
  z-index: 1;
  margin-right: 10px;
  opacity: 0.7;
}

.onlyArrow:hover {
  opacity: 1;
}

.developers,
.product_owners,
.analytics {
  color: #005580;
  cursor: pointer;
}

.displayFlex1 {
  display: flex;
}

.mrAuto {
  margin-right: auto;
}

.mAuto {
  margin-right: auto;
  margin-left: auto;
}

.map {
  display: flex;
  justify-content: center;
  margin: 15px;
}

@media screen and (max-width: 570px) {
  .displayFlex1 {
    display: block;
  }
  .container {
    position: relative;
    margin: 0 auto 20px auto;
    justify-content: center;
  }
}

.upArrow {
  position: fixed;
  bottom: 60px;
  left: calc(100vw - 200px);
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #f5fbfc;
  cursor: pointer;
  transition: height 0.5s, box-shadow 0.5s;
}

.upArrow:hover {
  height: 50px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);;
}

@media screen and (max-width: 1400px) {
  .upArrow {
    left: auto;
    right: 50px;
  }
}

@media screen and (max-width: 670px) {
  .upArrow {
    left: auto;
    right: 20px;
  }
}

@media screen and (max-width: 1099px) {
  .gallery {
    min-height: 225px;
  }
}

.headerMargin {
  margin-bottom: 30px;
}

.content {
  padding: 5px;
}

.error {
  color: #bf0b0b;
}
